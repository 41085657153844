<template>
  <Loading v-if="isInitLoading" />
  <div class="purchase" v-else>
    <Header @clickRight="clickRight">
      <template>申购专区</template>
      <!-- <template v-slot:right
        ><img class="doubt" src="../../../static/image/doubt.png" alt=""
      /></template> -->
    </Header>
    <div class="container" ref="scroll">
      <div class="list" v-for="item in list" :key="item.id" @click="goTo(item)">
        <div class="img">
          <img class="banner" :src="item.listPic" alt="" />
          <div class="timeOut" v-if="item.runBeginTime || item.runEndTime">
            <img src="../../../static/image/time.png" alt="" />
            <span v-if="item.runBeginTime"
              >开始倒计时: {{ item.runBeginTime | limitTimeFilter }}</span
            >
            <span v-else-if="item.runEndTime && !item.runBeginTime"
              >结束倒计时: {{ item.runEndTime | limitTimeFilter }}</span
            >
          </div>
          <div class="over" v-else>
            <img src="../../../static/image/over.png" alt="" />
          </div>
          <div class="tag" v-if="item.isApply">已申购</div>
        </div>
        <div class="name">{{ item.name }}</div>
        <div class="subTitle">
          申购时间: {{ item.applyBegin }} 至 {{ item.applyEnd }}
        </div>
      </div>
      <div class="def" v-if="!list.length">
        <img src="../../../static/image/emptyBox.png" alt="" />
        <span>暂无申购商品,敬请期待~</span>
      </div>
    </div>
    <van-popup v-model:show="showPopUp" class="popUp">
      <div class="header">申购规则</div>
      <div class="content" v-html="applyrule"></div>
      <div class="bottom" @click="showPopUp = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
import { formateTimeStamp } from "../../utils/tools";
export default {
  data() {
    return {
      showPopUp: false,
      ticker: null,
      isInitLoading: true,
      isNotMore: false,
      list: [],
      query: {
        pageindex: 1,
        pagesize: 20,
        token: localStorage.getItem("token"),
      },
    };
  },
  beforeDestroy() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener("scroll", this.onScroll);
    }
  },
  computed: {
    applyrule() {
      return this.$store.state.anyInfo.applyrule;
    },
  },
  mounted() {
    if (!this.applyrule) {
      this.$api.anyInfo().then((res) => {
        if (res.code == 0) {
          this.$store.commit("SET_ANYINFO", res.data);
        }
      });
    }
    this.init();
  },
  methods: {
    clickRight() {
      this.showPopUp = true;
    },
    init() {
      this.isNotMore = false;
      this.clearTimer();
      this.getList(1);
    },
    clearTimer() {
      if (this.ticker) {
        clearInterval(this.ticker);
      }
      if (this.$refs.scroll) {
        this.$refs.scroll.removeEventListener("scroll", this.onScroll);
      }
    },
    getList(isInit) {
      if (this.isNotMore) return;
      this.$api.applyGoodsList(this.query).then(
        (e) => {
          this.isInitLoading = false;
          if (e.code === 0) {
            if (e.data.length < this.query.pagesize) {
              this.isNotMore = true;
            }
            if (this.query.pageindex == 1) {
              this.list = e.data;
            } else {
              this.list = this.list.concat(e.data);
            }
            if (
              this.list.filter((e) => e.runBeginTime || e.runEndTime).length
            ) {
              this.beginTimer();
            }

            //
            if (isInit) {
              this.$nextTick(() => {
                const scroll = this.$refs.scroll;
                scroll.addEventListener("scroll", this.onScroll, true);
              });
            }
          } else {
            return this.$toast(e.msg);
          }
        },
        () => {
          this.isInitLoading = false;
        }
      );
    },
    onScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      if (clientHeight + scrollTop >= scrollHeight) {
        console.log("到底了");
        this.query.pageindex++;
        this.getList();
      }
    },
    goTo(item) {
      this.$router.push("/purchaseDetail?id=" + item.id);
    },
    beginTimer() {
      this.ticker = setInterval(() => {
        //数据循环，因为要每一列数据都要倒计时，所以要对每一列的数据进行处理
        for (let i = 0, len = this.list.length; i < len; i++) {
          const item = this.list[i];
          if (item.runBeginTime > 0) {
            this.list[i].runBeginTime = this.list[i].runBeginTime - 1;
          } else if (item.runEndTime > 0) {
            this.list[i].runEndTime = this.list[i].runEndTime - 1;
          }
        }
      }, 1000);
    },
  },
  filters: {
    limitTimeFilter(val) {
      if (val) {
        let formateLimitTimes = formateTimeStamp(val);
        let txt = `${formateLimitTimes.day} 天 ${formateLimitTimes.hour} 时 ${formateLimitTimes.min} 分	${formateLimitTimes.seconds} 秒`;
        if (Number(formateLimitTimes.day) > 0) {
          txt = `${Number(formateLimitTimes.day)} 天 ${
            formateLimitTimes.hour
          } 时 ${formateLimitTimes.min} 分   ${formateLimitTimes.seconds} 秒`;
        } else if (Number(formateLimitTimes.hour) > 0) {
          txt = `${formateLimitTimes.hour} 时 ${formateLimitTimes.min} 分   ${formateLimitTimes.seconds} 秒`;
        } else {
          txt = `${formateLimitTimes.min} 分   ${formateLimitTimes.seconds} 秒`;
        }
        return txt;
      } else {
        const twtxt = `0 天 0 时 0 分 0 秒`;
        return twtxt;
      }
    },
  },
};
</script>

<style scoped lang="less">
.purchase {
  .def {
    text-align: center;
    margin-top: 50px;
    img {
      width: 100px;
    }
    span {
      display: block;
      color: #999;
      font-size: 14px;
    }
  }
  .doubt {
    width: 18px;
  }
  .container {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 50px;
    background: #f8f8f8;
    padding: 20px;
    overflow: auto;
    .list {
      background: #fff;
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 20px;
      padding-bottom: 10px;
      box-shadow: 0 0 10px #eee;
      .img {
        position: relative;
        .over {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50%;
          }
        }
        img {
          max-width: 100%;
        }
        .banner {
          height: 360px;
          width: 100%;
          object-fit: cover;
        }
        .timeOut {
          position: absolute;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #00e3ab;
          padding: 3px 10px;
          background: rgba(27, 27, 27, 0.6);
          border-radius: 15px;
          position: absolute;
          left: 10px;
          top: 10px;
          display: flex;
          align-items: center;
          img {
            width: 13px;
            margin-right: 5px;
          }
        }
        .tag {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          padding: 3px 10px;
          background: rgba(0, 227, 171, 0.5);
          border-radius: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }
      .name {
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 15px;
        margin: 8px 0;
      }
      .subTitle {
        padding: 0 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
.popUp {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    height: 60vh;
    // min-height: 20vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
</style>
<style>
.purchase .content img {
  max-width: 100%;
}
</style>
